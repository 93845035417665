import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React, { useState, useEffect } from "react";
//import React from "react";
import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting,
    WithSearch
} from "@elastic/react-search-ui";
import {
    BooleanFacet,
    Layout,
    SingleLinksFacet,
    SingleSelectFacet
} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./workstreet.css";
import { SearchDriverOptions } from "@elastic/search-ui";
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
//import { BarChart, Text, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import logo from './assets/WS_main_logo_caps.png';

const SORT_OPTIONS = [
  {
    name: "Salary",
    value: [
      {
        field: "salary_max",
        direction: "desc"
      }
    ]
  },
  {
    name: "Newly Posted",
    value: [
      {
        field: "datePosted",
        direction: "desc"
      }
    ]
  },
  {
    name: "Relevance",
    value: []
  },
];


const connector = new AppSearchAPIConnector({
    searchKey: "search-7wbw3xudhzegyzmedsdgb82g",
    engineName: "main-jobs-engine-prd",
    endpointBase: "https://test-2.ent.us-west1.gcp.cloud.es.io"
});

const config: SearchDriverOptions = {
    alwaysSearchOnInitialLoad: false,
    apiConnector: connector,
    hasA11yNotifications: true,
    searchQuery: {
        result_fields: {
            symbol: { raw: {} },
            Name: { raw: {} },
            job_url: { raw: {} },
            title: { snippet: { fallback: true } },
            rank: { raw: {} },
            Industry: { raw: {} },
            one_address: { raw: {} },
            years: { raw: {} },
            salary: { raw: {} },
            datePosted: { raw: {} }
        },
        search_fields: {
            title: {},
            key_words: {},
            one_address: {}
        },
        // disjunctiveFacets: ["symbol", "rank", "title", "salary"],
        disjunctiveFacets: ["symbol", "rank", "years", "salary_max","one_address"],
        facets: {
            symbol: { type: "value", size: 30 },
            rank: { type: "value", size: 30 },
            // title: { type: "value", size: 30 },
            salary: { type: "value", size: 30 },
            one_address: { type: "value", size: 130 },
            years: {
        type: "range",
        ranges: [
          { from: 0, to: 3, name: "0 - 3" },
          { from: 4, to: 8, name: "4 - 8" },
          { from: 9, to: 14, name: "9 - 14" },
          { from: 15, name: "15+" }
        ]
      },
salary_max: {
        type: "range",
        ranges: [
          { from: 50000, to: 99000, name: "$50k - $99k"},
          { from: 100000, to: 149000, name: "$100k - $149k"},
          { from: 150000, to: 199000, name: "$150k - $199k"},
          { from: 200000, to: 249000, name: "$200k - $249k"},
          { from: 250000, to: 299000, name: "$250k - $299k"},
          { from: 300000, name: "$300k+ "}
        ]
      }
	
        }
    }
};


const CustomResultView: React.FC<{ result: any }> = ({ result }) => {
    const formattedTitle = { __html: result.title.snippet };
    const [data, setData] = useState<any>({});

    useEffect(() => {
        // Read the JSON file
        fetch(`./assets/symbol_data/${result.symbol.raw}.json`)
            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData);
            })
            .catch((error) => {
                console.error("Error fetching JSON data:", error);
              });
    }, []);

    return (
        <div style={{ borderRadius: '4px', padding: '4px 4px', margin: '4px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/* <a href={`${result.job_url.raw}?ref=workstreet.org`} target="_blank" rel="noopener noreferrer" */}
                <h3 dangerouslySetInnerHTML={formattedTitle} />
                <a href={`${result.job_url.raw}`} target="_blank" rel="noopener noreferrer"
                    style={{
                        background: 'linear-gradient(#50cb87, #01b150) #02b452',
                        borderRadius: '4px',
                        border: 'none',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset, rgba(59, 69, 79, 0.05) 0px 1px 0px',
                        color: 'white',
                        textShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px',
                        marginRight: '10px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        flexShrink: 0,
                        display: 'inline-block', // Ensure anchor behaves like a block element
                        textDecoration: 'none', // Remove underline from anchor
                        padding: '4px 8px', // Adjust padding as needed
                    }}
                >
                    Apply</a>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                <div>
                    <p><b>{result.Name.raw}</b></p>
                    {result.Industry && <p><b>Industry:</b> {result.Industry.raw}</p>}
                    {result.years && <p><b>Min. Years Experience:</b> {result.years.raw}</p>}
                    {result.salary && <p><b>Salary:</b> {result.salary.raw}</p>}
                    {result.datePosted && <p><b>Date Posted:</b> {result.datePosted.raw.substring(0,10)}</p>}
                    {result.one_address && <p><b>Location:</b> {result.one_address.raw}</p>}
                </div>
                <div>

                    <div>
                        {/* box for latest */}
{data.latest && (
    <h3 style={{ textAlign: "center" }}>{data.latest.reference_year} Financial Performance</h3>
)}
                        <div>
                            {data.latest &&
                                data.latest.facts.map((item: any) => (
                                    <div key={item.title}>
                                        {item.image && <img src={item.image} alt={item.title} style={{verticalAlign: 'middle',width: '3%', height: '3%'}} />}
                                        {item.image && item.title}
                                        {!item.image && item.title}
</div>
                                ))}
                        </div>                    

</div>
                    <div>
                        {/* box for historical */}

<div>
{data.historical && data.historical.facts && (
     <div>
 <h3 style={{ textAlign: "center" }}>Growth Since {data.historical.reference_year}</h3>

        {data.historical.facts.map((item: any) => (
            <div key={item.title}>
                {item.image && <img src={item.image} alt={item.title} style={{verticalAlign: 'middle' ,width: '4%' ,
                    height: '4%' , marginRight:' 3px'}} />}
                {item.image && item.title}
                {!item.image && item.title}
            </div>
        ))}

      </div>
  )}
</div>

                    </div>

                </div>
            </div>
        </div>
    );
};



export default function App() {
    return (
        <div>
            <SearchProvider config={config}>
                <WithSearch
                    mapContextToProps={({ wasSearched }) => ({
                        wasSearched
                    })}
                >
                    {({ wasSearched }) => {
                        return (
                            <div className="App">
                                <ErrorBoundary>
                                    <Layout
                                        header={
                                            <div>
                                                <header style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ width: '50%' }}>
                                                            <SearchBox
                                                                inputProps={{
                                                                    placeholder: 'Search for Jobs by title, keyword, location'
                                                                }}
                                                                debounceLength={0} />
                                                        </div>
                                                    </div>
                                                </header>
                                            </div>
                                        }
                                        sideContent={<div>
{wasSearched && (
  <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} />
)}                                            
<Facet
                                                field="years"
                                                label="Min Years of Experience"
                                                filterType="any"
                                            />
                                            <Facet
                                                field="one_address"
                                                label="Location"
                                                isFilterable={true}
                                                filterType="any"
                                            />
                                            <Facet
                                                field="salary_max"
                                                label="Salary"
                                                filterType="any"
                                            />
                                            <Facet
                                                field="rank"
                                                label="rank"
                                                isFilterable={true}
                                                filterType="any"
                                            />
                                        </div>}
                                        bodyContent={
                                            <div>
                                                <Results
                                                    resultView={CustomResultView}
                                                    titleField=""
                                                    urlField=""
                                                    shouldTrackClickThrough
                                                />
                                            </div>
                                        }
                                        bodyHeader={
                                            <React.Fragment>
                                                {wasSearched && <PagingInfo />}
                                                {wasSearched && <ResultsPerPage />}
                                            </React.Fragment>
                                        }
                                        bodyFooter={<Paging />}
                                    />
                                </ErrorBoundary>
                            </div>
                        );
                    }}
                </WithSearch>
            </SearchProvider>

        </div>
    );
}
